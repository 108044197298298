<template>
    <div id="acessTypeMenu">
        <div class="btnMenu" id="btnMenu">
            <img src="~@/assets/icons/FirebeeIcon.svg" class="RdpLogoMenu" @click='openMenu()'/>
            <div v-if="showMenu" class="areaMenu">
                <a v-if="$router.currentRoute.name == 'RDP'" class="float floatFirstIten" @click="ssh()">
                    <img class="imgBtnFloat" src="~@/assets/icons/AccessType/ssh.svg">
                </a>
                <a v-else class="float floatFirstIten" @click="rdp()">
                    <img class="imgBtnFloat" src="~@/assets/icons/AccessType/rdp.svg">
                </a>
                <a class="float floatSecondIten" :class="{btnTypeDisabled: true}" @click="ftp()">
                    <img class="imgBtnFloat" src="~@/assets/icons/rdp/folder.svg">
                </a>
                <a class="float floatThirdIten" :class="{btnTypeDisabled: $router.currentRoute.name == 'SSH'}" @click="settings()">
                    <img class="imgBtnFloat" src="~@/assets/icons/rdp/setting.svg">
                </a>
                <a class="float floatFourthIten" @click="close()">
                    <img class="imgBtnFloat" src="~@/assets/icons/rdp/exit.png">
                </a>
            </div>
        </div>
        <div v-if="settingsEdit" class="settinsArea textAverage">
            <div class="titleSettings">Configuração RDP</div>
            <div>Qualidade:</div>
            <div class="sliderArea">
                <div class="range-slider">
                    <input min="0" max="9" step="1" type="range" v-model="quality" @mouseup="setVolume()" @touchend="setVolume()"/>
                </div>
            </div>
            <div class="labelFLotLeft">Baixa</div>
            <div class="labelCenter">Média</div>
            <div class="labelFLotRight">Alta</div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        rdp(){
            this.$router.push({ name: 'RDP', params: { device: this.device }})
        },
        ssh(){
            this.$router.push({ name: 'SSH', params: { device: this.device }})
        },
        ftp(){
            
        },
        settings(){
            if(this.$router.currentRoute.name != 'RDP'){ return }
            this.settingsEdit = !this.settingsEdit
        },
        setVolume(){
            this.$emit('chageQualityRDP', this.quality)
        },
        openMenu(){
            this.showMenu = !this.showMenu
            this.settingsEdit = false
        },
        closeMenu(){
            this.showMenu = false
            this.settingsEdit = false
        },
        close(){
            this.$router.push({ name: 'AcessType', params: { device: this.device }});
        },
        clickOutSide(e){
            if (!document.getElementById('acessTypeMenu').contains(e.target)){
                this.closeMenu()
            }
        }
    },
    mounted(){
        window.addEventListener('click', this.clickOutSide)
    },
    destroyed(){
        window.removeEventListener('click', this.clickOutSide)
    },
    data(){
        return{
            showMenu: false,
            settingsEdit: false,
            quality: 6
        }
    },
    props: {
        device:{
            type: Object,
            default:null
        }
    },
    directives: {
        
    }
}
</script>

<style>
    .btnMenu{
        position: absolute;
        display: flex;
        right: 0px;
        top: 50%;
        height: fit-content;
        width: fit-content;
        transform: translate(0, -50%);
        background-color: rgba(0, 0, 0, 0.50);
        border-radius: 5px 0px 0px 5px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
        margin: 0px;
        z-index: 12;
    }
    .RdpLogoMenu{
        height: 36px;
        width: 36px;
        margin: 8px;
    }
    .imgBtnFloat{
        width: 30px;
    }
    .float{
        display: flex;
        position:absolute;
        width:50px;
        height:50px;
        right: 10px;
        border-radius:50%;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
        cursor: pointer;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        background-color:#4375EF;
    }
    .btnTypeDisabled{
        background-color: #999999;
    }
    .areaMenu{
        position: absolute;
    }
    .floatFirstIten{
        top:-68px;
        right: -30px;
    }
    .floatSecondIten{
        top: -28px;
        right: 10px;
    }
    .floatThirdIten{
        top: 28px;
        right: 10px;
    }
    .floatFourthIten{
        top: 68px;
        right: -30px;
        background-color:#ff3c3c;
    }
    .settinsArea{
        position: absolute;
        right: 130px;
        width: 400px;
        padding: 10px;
        border-radius: 6px;
        background-color: #292d32;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
        top: 50%;
        transform: translate(0, -50%);
    }
    .titleSettings{
        text-align: center;
        height: 35px;
    }
    .labelFLotLeft{
        float: left;
    }
    .labelCenter{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .labelFLotRight{
        float: right;
    }
    .sliderArea{
        height: 65px;
        width: 95%;
        right: 0;
        left: 0;
        margin: 0 auto
    }
    .range-slider {
        width: 100%;
        height: 35px;
        top: 0px;
        position: relative;
    }
    .range-slider svg,
    .range-slider input[type=range] {
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 5px;
    }
    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        z-index: 2;
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: #4375EF;
        border-radius: 5px;
        box-shadow: none
    }
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        z-index: 2;
        position: relative;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
        border: 3px solid #36393F;
        height: 40px;
        width: 30px;
        border-radius: 10px;
        background: #1e2227;
        cursor: pointer;
        margin-top: -15px;
    }
    @media(max-width: 1280px){
        .settinsArea{
            width: 350px;
        }
        input[type=range]::-webkit-slider-runnable-track {
            height: 8px;
        }
        input[type=range]::-webkit-slider-thumb {
            margin-top: -14px;
            height: 36px;
            width: 25px;
        }
    }
    @media(max-width: 825px){
        .sliderArea{
            height: 50px;
        }
        .range-slider {
            height: 25px;
        }
        .settinsArea{
            width: 300px;
        }
        input[type=range]::-webkit-slider-runnable-track {
            height: 6px;
        }
        input[type=range]::-webkit-slider-thumb {
            margin-top: -13px;
            height: 30px;
            width: 23px;
        }
    }
</style>