export enum ConnectionStatus{
    STATUS_INIT                                             =0,//Status inicial
    STATUS_IDENTIFYING                                      =1,//Status usado pelo dispositivo para indicar que ele está se identificando no servidor
    STATUS_WAITING_FOR_APPROVAL                             =2,//O dispositivo entra nesse status se o mesmo não possuir token de acesso
    STATUS_APPROVED_DEVICE                                  =3,//O dispositivo entra nesse status se o mesmo for aprovado por um usuario
    STATUS_RESET_TOKEN                                      =4,//O dispositivo entra nesse status caso servidor identifique a necessidade de trocar o token desse dispositivo seja porque o token está invalido ou porque o usuario solicitou que fosse alterado o token desse dispositivo.(Não implementado do lado servidor, porem o diposittivo vais estar preparado para isso caso seja necessario)
    STATUS_IDENTIFIED                                       =5,//Status utilizado para o servidor informar que o dispositivo foi identificado com sucesso
    STATUS_INTERCONECTING                                   =6,//status utilizado para o usuario informa que precisa interconectar com um dispositivo
    STATUS_INTERCONECT_ACCEPT                               =7,//status utilizado para o servidor que a solicitação de interconexão com o dispositivo foi devidamente aceita e que essa solicitação foi enviada para o dispositivo
    STATUS_INTERCONECTED                                    =8,//status utilizado para o dispositivo informar para o servidor que a conexão com serviço foi concluida, que por sua vez notifica o cliente sobre o sucesso da operação
    STATUS_FAULT_ON_SOCKET                                  =-1,//indica falha na conexão
    STATUS_FAULT_CONNECTION_LOST                            =-2,//indica que uma das parte perdeu conexão
    STATUS_FAULT_TIMEOUT                                    =-3,//indica que em uma das etapas do processo o tempo expirou e a mesma não foi concluida
    STATUS_FAULT_ACCESS_DENIED                              =-4,//indica que o acesso foi negado pelo servidor ou pelo dispositivo
    STATUS_FAULT_DEVICE_NOT_CONNECTED                       =-5,//indica que o dispositivo não está conectado no servidor
    STATUS_FAULT_ON_SERVICE                                 =-6,//indica que o seriço requerido está em falha e não foi possivel conectar
    STATUS_FAULT_INVALID_STATUS                             =-7,//indica que o status recebido não é o esperado
    STATUS_FAULT_INVALID_DATA                               =-8,//indica que os dados recebidos estão corrompidos
    STATUS_FAULT_ON_SERVER                                  =-9,//indica que houve alguma falha no servidor
}