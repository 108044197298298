<template>
    <div>     
        <PopUp  :msg="statusMsg" :show="showStatus" type="Loader"/> 
        <PopUp  :msg="statusMsg" :show="showFault" type="'Confirmation'" :onOk="onReconnect" :onCancel="onExit"/>
    </div>
</template>
<script>
    import {BridgeStatus} from '@/sys/FireBridge/FireBrige'
    import PopUp from '@/views/PopUp/PopUp.vue'
    export default {
        components:{PopUp},
        data() {
            return {
                lastStatus:null,
                showStatus:false,
                showFault:false,
                statusMsg:""
            }
        },
        props: {
            fireBridge:{
                type: Object,
                default:null
            },
            loadMsg:{
                type: String,
                default:null
            },
            loaded:{
                type:Boolean,
                default:false
            },
            onReconnect:{
                type: Function,
                default: ()=>{}
            },
            onExit:{
                type: Function,
                default: ()=>{}
            }
        },
        methods:{
            checkLoaded(){
                setTimeout(()=>{
                    if(this.loaded){
                        setTimeout(()=>this.showStatus=false,1500);
                    }else{
                        this.checkLoaded();
                    }
                },200)
            },
            fireBridgeStatus(status){
                console.log("fireBridgeStatus: "+ status);
                if(status>=0){
                    this.showFault=false;
                    this.showStatus=true;
                    switch(status){
                        case BridgeStatus.STATUS_REGISTERING_CONNECTION:
                            this.statusMsg="Registrando conexão";                           
                        break;
                        case BridgeStatus.STATUS_CONNECTING:
                            this.statusMsg="Conectando";
                        break;
                        case BridgeStatus.STATUS_CONNECTION_COMPLETED:
                            this.statusMsg=(this.loadMsg?this.loadMsg:"Iniciando Serviço");                         
                            this.checkLoaded();
                        break;
                    }
                }else{
                    this.showFault=true;
                    this.showStatus=false;
                    if(this.lastStatus==BridgeStatus.STATUS_CONNECTION_COMPLETED){
                        this.statusMsg="Conexão encerrada, deseja reconectar?";
                    }else{
                        switch(status){
                            case BridgeStatus.STATUS_FAULT_ON_SOCKET:    
                            case BridgeStatus.STATUS_FAULT_CONNECTION_LOST:
                            case BridgeStatus.STATUS_FAULT_TIMEOUT:
                            case BridgeStatus.STATUS_FAULT_INVALID_STATUS:
                            case BridgeStatus.STATUS_FAULT_INVALID_DATA:
                            case BridgeStatus.STATUS_FAULT_ON_SERVER:
                                this.statusMsg="Falha ao conectar no dispositivo, deseja tentar novamente?"
                            break;
                            case BridgeStatus.STATUS_FAULT_ON_SERVICE:
                                this.statusMsg="Serviço indisponivel no dispositivo, deseja tentar novamente?"
                            break;
                            case BridgeStatus.STATUS_FAULT_ACCESS_DENIED:
                                this.statusMsg="Acesso Negado, deseja tentar novamente";
                            break;
                            case BridgeStatus.STATUS_FAULT_DEVICE_NOT_CONNECTED:
                                this.statusMsg="Dispositivo não esta conectado, favor verificar a conexão do mesmo. Deseja tentar novamente?"
                            break;
                            
                        }
                    }
                    this.showStatus=false;
                }
                this.lastStatus=status;
            },
            popUpOk(){
                console.log("Reconectando");
            }
        },
        mounted(){
            console.log("Update");
            this.fireBridge.callbackStatus=this.fireBridgeStatus;
        }
    }  
</script>
<style scoped>

</style>