import {ConnectionStatus} from './ConnectionStatus';

export type CallbackSendData=(this:BridgeInterface, data:MessageEvent)=>void;
export type CallbackFault=(this:BridgeInterface)=>void;


export abstract class BridgeInterface{
    protected status:ConnectionStatus=ConnectionStatus.STATUS_INIT;
    protected wsConnection:WebSocket;
    protected callbackSendData:CallbackSendData;
    protected callbackFault:CallbackFault;
    
    constructor(wsConnection:WebSocket){
        this.wsConnection=wsConnection;
        this.wsConnection.onmessage=this.onSocketMessage.bind(this);
        this.wsConnection.onclose=this.onSocketClose.bind(this);
        this.wsConnection.onerror=this.onSocketError.bind(this);
    }

    protected setStatus(status:ConnectionStatus, sendStatus:Boolean):void {   
        if (this.status >= ConnectionStatus.STATUS_INIT) {
            this.status = status;
            if (sendStatus)
            {
                var json={
                    status:this.status
                }
                this.sendData(JSON.stringify(json));
                console.log("Send to Server: "+JSON.stringify(json)); 
            }     

            if (this.status < ConnectionStatus.STATUS_INIT) {
                if(this.wsConnection.readyState<=1){
                    console.log("Force Close socket"+this.wsConnection.readyState);
                    this.wsConnection.close();//encerra visto que a mesma está com problemas
                }
                this.connectionLost(this.status);
            }
        }        
    }

    protected setStatusAndData(status:ConnectionStatus, data:Object):void{   
        if (this.status >= ConnectionStatus.STATUS_INIT) {
            this.status = status;
            var json={
                status:this.status,
                data:data
            }
            this.sendData(JSON.stringify(json));
            console.log("Send to Server: "+JSON.stringify(json));
            
            if (this.status < ConnectionStatus.STATUS_INIT) {                
                if(this.wsConnection.readyState<=1){
                    console.log("Force Close socket"+this.wsConnection.readyState);
                    this.wsConnection.close();//encerra visto que a mesma está com problemas
                }
                this.connectionLost(this.status);
            }      
        }       
    }

    public setCallbackSendData(callbackSendData:CallbackSendData){
        this.callbackSendData=callbackSendData;
    }

    public setCallbackFault(callbackFault:CallbackFault){
        this.callbackFault=callbackFault;
    }

    public sendData(data:any){
        this.wsConnection.send(data);
    }

    protected onSocketClose(ev: CloseEvent){
        console.log("Code: "+ev.code+" reason: "+ev.reason);
        this.setStatus(ConnectionStatus.STATUS_FAULT_CONNECTION_LOST,false);
    };
    protected onSocketError(ev: Event){
        console.log("Socket erro: "+ev.type);
        this.setStatus(ConnectionStatus.STATUS_FAULT_CONNECTION_LOST,false);
    };
    
    protected abstract onSocketMessage(ev:MessageEvent):void;
    protected abstract connectionLost(status?:ConnectionStatus):void;
}