import {ConnectionStatus} from './ConnectionStatus';
import { BridgeInterface } from './BridgeInterface';

export default class Bridge extends BridgeInterface{
    private onInterconnect:()=>void;
    private onConnectionLost:(status?:ConnectionStatus)=>void;

    constructor(wsConnectionConnection:WebSocket, token:String,onInterconnect:()=>void,onConnectionLost:(status?:ConnectionStatus)=>void){//Construtor de uma ponte Cliente Dispositivo
        super(wsConnectionConnection);
        this.status=ConnectionStatus.STATUS_INIT;
        this.onInterconnect=onInterconnect;
        this.onConnectionLost=onConnectionLost;
        this.interconnect(token);
    }   

    protected connectionLost(status?:ConnectionStatus){
        this.onConnectionLost(status);
    };

    protected interconnect(token){
        console.log('>> FireBrige.onInterconnect');
        if(this.status==ConnectionStatus.STATUS_INIT){
            this.setStatusAndData(ConnectionStatus.STATUS_INTERCONECTING,token);            
        }else{
            throw "Invalid State";
        }
        console.log('<< FireBrige.onInterconnect');
    }

    protected onSocketMessage(ev:MessageEvent){
        if(this.status==ConnectionStatus.STATUS_INTERCONECTED){
            console.log("Receive: "+new Uint8Array(ev.data));
        }else{
            console.log("Receive: "+ev.data);
            try{             
                var json=JSON.parse(ev.data);
                switch (json.status) {
                    case ConnectionStatus.STATUS_INTERCONECT_ACCEPT://Indica que o servidor aprovou o pedido de interconexão
                        this.setStatus(ConnectionStatus.STATUS_INTERCONECT_ACCEPT,false);
                        /* Equivalente ao status 8 da esp (system info) */
                        console.log("System Info ESP: ", json);
                    break;
                    case ConnectionStatus.STATUS_INTERCONECTED://indica que a central conseguiu conectar no serviço
                        this.setStatus(ConnectionStatus.STATUS_INTERCONECTED,false);
                        this.onInterconnect();//informa que a ponte foi criada            
                    break;    
                    case ConnectionStatus.STATUS_FAULT_ACCESS_DENIED://Falhas que podem ocorre durante a conexão
                    case ConnectionStatus.STATUS_FAULT_DEVICE_NOT_CONNECTED:
                    case ConnectionStatus.STATUS_FAULT_ON_SERVICE:
                    case ConnectionStatus.STATUS_FAULT_TIMEOUT:
                    case ConnectionStatus.STATUS_FAULT_INVALID_STATUS:
                        this.setStatus(json.status,false);
                    break;
                    default:
                        this.setStatus(ConnectionStatus.STATUS_FAULT_ON_SERVER,true);
                    break;
                }
            }catch(e){
                console.log(e);
                this.setStatus(ConnectionStatus.STATUS_FAULT_ON_SERVER,true);            
            }        
        }
    };

    

}
